import React from 'react';

import css from './SectionGetMore.module.css';

import imgSrc from './image.png';
import Accordeon from '../Accordeon/Accordeon';
import LinkButton from '../LinkButton/LinkButton';

const dataArr = [
  {
    label: 'Take Our Free Side Hustle Quiz',
    content: (
      <>
        <p className={css.content}>
          Discover the perfect side hustle for you by taking our quick and easy quiz. Whether you're
          looking to make extra income or turn your passion into profit, our quiz will guide you to
          the best options. Ready to find your match?
        </p>
        <LinkButton link="." text="Start the Quiz" />
      </>
    ),
  },
  {
    label: 'Join Our Free Community',
    content: (
      <>
        <p className={css.content}>
          Enjoy live masterclasses, co-working sessions, and monthly giveaways. Get exclusive
          guides, discounts, and more. Say no to gurus and yes to real success!
        </p>
        <LinkButton link="/signup" text="Join the Community" />
      </>
    ),
  },
];

const SectionGetMore = () => {
  return (
    <div className={css.root}>
      <div className={css.textContainer}>
        <p className={css.title}>Get More From MentorME</p>
        <img src={imgSrc} className={css.pictureMobile} />
        <Accordeon radioName={'GetMore'} dataArr={dataArr} />
      </div>
      <img src={imgSrc} className={css.pictureDesktop} />
    </div>
  );
};

export default SectionGetMore;
