import React from "react";

import css from './SectionRecommendations.module.css'
import uniqueId from "lodash/uniqueId";
import image1 from './images/image1.png'
import image2 from './images/image2.jpg'
import image3 from './images/image3.png'
import image5 from './images/image5.png'
import image6 from './images/image6.png'

const dataArr = [{
    imageSrc:image1,
    review:"“Great platform! Jack was incredibly helpful and pointed me in the direction of Theo when I mentioned the issue I had within my business. Theo offered great guidance on the issues I had and has subsequently saved me 100s/1000s of pounds in mistakes. Unbelievable value! Overall great experience and I would recommend anyone to just get in touch.”",
    name:"Jonathan R"
},
{
    imageSrc:image2,
    review:"“Great guy, has always answered any questions I have had, the 8 week course was great aswell, he set out actionable steps each week to make sure we were all moving forward, would definitely recommend if looking to get started with FBA wholesale”",
    name:"Connall W"
},{
    imageSrc:image3,
    review:"“Mentorship with Paul Stapleton has been incredibly useful and has given me a substantial understanding of property sourcing. It has helped start my property sourcing business and reach key milestones in a matter of months”",
    name:"Krishna K"
},{
    imageSrc:"https://t3.ftcdn.net/jpg/02/22/85/16/360_F_222851624_jfoMGbJxwRi5AWGdPgXKSABMnzCQo9RN.jpg",
    review:"“I've had a fantastic experience with this site, and the mentors are incredibly helpful. The assurance that they are vetted before joining adds an extra layer of safety, ensuring you're learning from a qualified mentor with substantial expertise in their field. I can't find any faults, and I wholeheartedly recommend it. Thanks once again!”",
    name:"Colwyn W"
},{
    imageSrc:image5,
    review:"“The whole process was made super easy. Jack from Mentor Me was great to chat to to get some key info and allowed it for an easy exchange between me and Jack P (the mentor). The experience was probably one of the best mentorship calls I’ve ever taken. So far got 2 zoom meetings scheduled and look forward to taking on a brand. Lot of very useful information in the call and Jacks great to get on with. Look forward to proving his strategies right and showcasing my results.”",
    name:"Joe H"
},{
    imageSrc:image6,
    review:"“What Jack has created is absolutely brilliant. The access to people who are actually doing online business/side hustles is amazing and it’s all for free until you choose a mentor they you are subjected to their fees, but some you get great discounts just by being a member of MentorMe. My knowledge is growing and I’ve actually signed up with one of the mentors. My confidence is growing because of MentorMe. Jack and the mentors cut through all of the bulls*** there are actual genuine ways to earn online.“",
    name:"Jack, UK"
}]

const SectionRecommendations = () => {
    return(
        <div className={css.root}>
            <p className={css.title}>See what our mentees are saying</p>
            <div className={css.reviewGrid}>
                {dataArr.map(elem=><Review key={uniqueId("SectionRec")}imageSrc={elem.imageSrc} review={elem.review} name={elem.name}/>)}
            </div>
        </div>
    )
}

const Review = ({imageSrc, review, name}) => {
    return(
        <div className={css.reviewContainer}>
            <img src={imageSrc} className={css.reviewImage}/>
            <p className={css.reviewText}>{review}</p>
            <p className={css.reviewName}>{name}</p>
        </div>
    )
}

export default SectionRecommendations