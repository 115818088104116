import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionBusinessMentors,
  SectionComparison,
  SectionDecideApply,
  SectionExplore,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  SectionPopularMentors,
  SectionSchedule,
  SectionSideHustles,
  SectionStatistics,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { getProfilesById, getListingsById } from '../../ducks/marketplaceData.duck';

import {
  fetchPopularProfiles,
  fetchBusinessListings,
  fetchSideHustleListings,
  fetchAll,
} from './LandingPage.duck';

import css from './LandingPage.module.css';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import SectionGuaranteeingQuality from '../../components/SectionGuaranteeingQuality/SectionGuaranteeingQuality';
import SectionHowItWorksNew from '../../components/SectionHowWorks/SectionHowItWorks';
import SectionGetMore from '../../components/SectionGetMoreFromMentorMe/SectionGetMore';
import SectionFaq from '../../components/SectionFaq/SectionFaq';
import SectionMeetMentor from '../../components/SectionMeetMentor/SectionMeetMentor';
import SectionRecommendations from '../SectionRecommendations/SectionRecommendations';


const MAX_MOBILE_SCREEN_WIDTH = 768;

export const LandingPageComponent = props => {
  const [searchHidden, setSearchHidden] = useState(false);
  const [heroImageIndex, setHeroImageIndex] = useState(1);
  const {
    history,
    intl,
    viewport,
    location,
    currentUser,
    scrollingDisabled,
    popularProfileResultIds,
    businessListingResultIds,
    sideHustleListingResultIds,
    popularProfiles,
    businessListings,
    sideHustleListings,
    onFetchPopularProfiles,
    onFetchBusinessListings,
    onFetchSideHustleListings,
    onFetchAll,
  } = props;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "m9qigmdcmn");
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const heroImageTimer = setInterval(() => handleHeroImageSwitch(), 10000);

    //    if( !Array.isArray( popularProfileResultIds ) || popularProfileResultIds.length === 0 )
    //      setTimeout(() => handleProfilesFetch(), 1 );

    return () => {
      clearInterval(heroImageTimer);
    };
  }, [heroImageIndex]);

  const handleHeroImageSwitch = () => {
    if (heroImageIndex < 4) setHeroImageIndex(heroImageIndex + 1);
    else setHeroImageIndex(1);
  };

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer heroImageIndex={heroImageIndex} searchHidden={searchHidden} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer} onScroll={() => console.log('ERTY')}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              heroImageIndex={heroImageIndex}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentExplore}>
                <SectionMeetMentor />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentExplore}>
                <SectionGuaranteeingQuality />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentExplore}>
                <SectionHowItWorksNew />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentExplore}>
                <SectionGetMore />
              </div>
            </li>
            <li className={css.recommendationContainer}>
              <div className={css.sectionContentExplore}>
                <SectionRecommendations />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentExplore}>
                <SectionExplore intl={intl} isMobileLayout={isMobileLayout} />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContentExplore}>
                <SectionFaq />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    popularProfileResultIds,
    businessListingResultIds,
    sideHustleListingResultIds,
  } = state.LandingPage;
  const popularProfiles = getProfilesById(state, popularProfileResultIds);
  const businessListings = getListingsById(state, businessListingResultIds);
  const sideHustleListings = getListingsById(state, sideHustleListingResultIds);
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    popularProfileResultIds,
    businessListingResultIds,
    sideHustleListingResultIds,
    popularProfiles,
    businessListings,
    sideHustleListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onFetchPopularProfiles: profileIds => dispatch(fetchPopularProfiles(profileIds)),
  onFetchBusinessListings: listingIds => dispatch(fetchBusinessListings(listingIds)),
  onFetchSideHustleListings: listingIds => dispatch(fetchSideHustleListings(listingIds)),
  onFetchAll: (profileIds, businessIds, hustleIds) =>
    dispatch(fetchAll(profileIds, businessIds, hustleIds)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
